<template>
  <div class="director">
    <div class="search-wrap">
      <div class="title">{{ $t("unitDirector") }}</div>
      <div class="searchBtn">
        <searchCompany
          genreType="2"
          v-model="queryParams.companyName"
          @search="search"
          class="search-wrap-box"
          :placeTitle="$t('ccompanyPlaceholder')"
        />
      </div>
    </div>
    <filterSearch @handleSearch="handleSearch" :isShow="isShow" />
    <div class="main">
      <div>
        <unitItem
          v-for="(item, index) in companyList"
          :number="number"
          :companyInfo="item"
          :key="index"
          :iconShow="2"
        />
      </div>
      <isLoadingTip v-if="loading" />
      <isAllDataTip
        v-if="companyList.length && total === companyList.length && !loading"
      />
      <noDataImg v-if="companyList.length === 0"></noDataImg>
      <div class="clearfix" v-show="companyList.length">
        <el-pagination
          class="fr"
          style="margin-top: 10px"
          :current-page="currentPage + 1"
          @current-change="handleChange"
          :total="total"
          background
          :page-size="10"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import searchCompany from "@/projects/henanSociety/components/company/unit/searchCompany";
import unitItem from "@/projects/henanSociety/components/company/unit/unitItem";
import filterSearch from "~hns/components/common/filterSearch";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
export default {
  metaInfo() {
    return {
      title: "河南省国际货运代理协会理事单位",
      meta: [
        // {
        //   name: "keyWords",
        //   content: "上海市国际货运代理行业理事单位,上海货代理事单位",
        // },
        // {
        //   name: "description",
        //   content:
        //     "上 海 市 国 际 货 运 代 理 行 业 协 会 （ shanghai international freight forwarders association）是在我国改革开放不断深化，国际货运代 理业快速发展条件下，于 1992 年 9 月成立，是我国（除港澳台地区外）最早成立的省 市级国际货运代理行业协会。",
        // },
      ],
    };
  },
  name: "director",
  components: {
    searchCompany,
    unitItem,
    filterSearch,
    isLoadingTip,
    isAllDataTip,
  },
  data() {
    return {
      number: 2,
      currentPage: 0,
      total: 0,
      loading: false,
      queryParams: {
        companyName: "",
      },
      filters: {},
      companyList: [],
      isShow: false, //搜索项是否显示单位类型
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleChange(index) {
      this.currentPage = index - 1;
      this.getList();
    },
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    async getList(type) {
      this.loading = true;
      let params = {};
      params.start = this.currentPage;
      params.limit = 10;
      params.name = this.queryParams.companyName;
      params.city_id = this.filters.city;
      params.company_credit = this.filters.credit;
      params.genre_ids = this.filters.unitType;
      // params.certificate=20
      params.source = this.PJSource;
      params.genre_ids = 2;
      params.user_id = this.USER_ID;
      // params.source_type=1
      let res = await this.$store.dispatch(
        "API_company/getCompanyList",
        params
      );
      if (res.success) {
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        res.data.forEach((item, index) => {
          if (item.genre_ids.length > 1) {
            item.genre_ids = item.genre_ids.filter((ee) => ee != "1");
          }
        });
        this.companyList = res.data;
        this.total = res.total;
        this.loading = false;
        document.body.scrollIntoView();
      }
    },
    handleCurrentChange() {},
    search() {
      this.currentPage = 0;
      this.getList();
    },
    handleSearch(val) {
      this.filters = val;
      this.currentPage = 0;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
.director {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    .search-wrap-box {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
    }
  }
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
.main {
  margin-top: 20px;
  position: relative;
  min-height: 200px;
}
</style>
